import { Link } from 'gatsby';
import React, { Fragment } from 'react';
import styled from 'styled-components';
import LogoSvg from '../assets/icons/logo.svg';
import footerContent from '../../content/components/footerForToolkit.yml';
import ButtonDefault from './ButtonDefault';
import GooglePlayBg from '../assets/icons/googlePlayBgWhite.svg';
import AppleStoreBg from '../assets/icons/appStoreBgWhite.svg';
import ContactIcon from './ContactIcon';
import { Body4 } from './Typography';

const FooterWrapper = styled.div`
  padding: 5rem;
  border-top: 1px solid #d9d9d9;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: 1200px) {
    padding: 2rem;
  }
`;

const FooterSection = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 1480px) {
    margin: 0 0.5rem;
  }
  @media (max-width: 1200px) {
    margin-top: 2rem;
  }
`;

const FooterTitle = styled.p`
  font-size: 17px;
  font-weight: 700;
  line-height: 26px;
  margin-bottom: 1rem;
  color: #13273f;
`;
const FooterLink = styled(Link)`
  text-decoration: none;
  font-size: 15px;
  line-height: 30px;
  color: #495b6c;
  &:hover {
    text-decoration: underline;
  }
`;

const FooterContactLabel = styled.p`
  color: #8181a5;
  font-size: 15px;
  font-weight: 500;
  line-height: 25px;
  margin-top: 1rem;
`;

const FooterContact = styled.p`
  color: #13273f;
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
`;

// const FooterContactEmail = styled.p`
//   color: #13273f;
//   font-size: 15px;
//   font-weight: 500;
//   line-height: 25px;
// `;

const FooterButtons = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const AppStore = styled(AppleStoreBg)`
  height: 100%;
  width: 100%;
`;

const GooglePlay = styled(GooglePlayBg)`
  height: 100%;
  width: 100%;
`;

const ContactLink = styled.a`
  text-decoration: none;
  color: inherit;
`;

const Socials = styled.div``;

const RightsReserved = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid rgba(217, 217, 217, 0.4);
  padding: 1rem 5rem;
`;

const CopyRight = styled(Body4)``;

const ComingSoonSpan = styled.span`
  color: #ef6355;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  /* margin-left: 15px; */
  /* &:hover {
    text-decoration: none;
  } */
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

interface NavSection {
  title: string;
  appStoreButton: string;
  googlePlayButton: string;
  links: {
    label: string;
    url: string;
    comingSoon?: boolean;
  }[];
}

interface LayoutFooterContent {
  mainSection: {
    contacts: {
      label: string;
      contact: string;
    }[];
    socials: {
      type: SocialType;
      url: string;
    }[];
  };
  businessToolkitSection: NavSection;
  paymentsSection: NavSection;
  resourcesSection: NavSection;
  legalSection: NavSection;
}

const FooterForToolkit: React.FunctionComponent = () => {
  const {
    mainSection,
    businessToolkitSection,
    paymentsSection,
    resourcesSection,
    legalSection,
  } = footerContent as unknown as LayoutFooterContent;

  const actualYear = new Date().getFullYear();
  return (
    <>
      <FooterWrapper>
        <div>
          <Link to="/">
            <LogoSvg />
          </Link>
          {mainSection.contacts.map((item) => (
            <Fragment key={item.label}>
              <FooterContactLabel>{item.label}</FooterContactLabel>
              {item.contact.includes(`+`) ? (
                <ContactLink href={`tel:${item.contact}`}>
                  <FooterContact>{item.contact}</FooterContact>
                </ContactLink>
              ) : (
                <ContactLink href={`mailto:${item.contact}`}>
                  <FooterContact>{item.contact}</FooterContact>
                </ContactLink>
              )}
            </Fragment>
          ))}

          <Socials>
            <FooterContactLabel>Social media</FooterContactLabel>
            {mainSection.socials.map((item) => (
              <a
                key={item.url}
                target="_blank"
                href={item.url}
                rel="noreferrer"
              >
                <ContactIcon type={item.type} />
              </a>
            ))}
          </Socials>
        </div>
        <FooterSection>
          <FooterTitle>{businessToolkitSection.title}</FooterTitle>
          {businessToolkitSection.links.map(({ label, url, comingSoon }) => (
            <Row>
              <FooterLink key={url} to={url}>
                {label}
              </FooterLink>
              {comingSoon && <ComingSoonSpan>COMING SOON!</ComingSoonSpan>}
            </Row>
          ))}
        </FooterSection>
        <FooterSection>
          <FooterTitle>{paymentsSection.title}</FooterTitle>
          {paymentsSection.links.map(({ label, url, comingSoon }) => (
            <Row>
              <FooterLink key={url} to={url}>
                {label}
              </FooterLink>
              {comingSoon && <ComingSoonSpan>COMING SOON!</ComingSoonSpan>}
            </Row>
          ))}
        </FooterSection>
        <FooterSection>
          <FooterTitle>{resourcesSection.title}</FooterTitle>
          {resourcesSection.links.map(({ label, url }) => (
            <FooterLink key={url} to={url}>
              {label}
            </FooterLink>
          ))}
        </FooterSection>
        <FooterSection>
          <FooterTitle>{legalSection.title}</FooterTitle>
          {legalSection.links.map(({ label, url }) => (
            <FooterLink key={url} to={url}>
              {label}
            </FooterLink>
          ))}
          <FooterButtons>
            <ButtonDefault withImage to={legalSection.googlePlayButton}>
              <GooglePlay />
            </ButtonDefault>
            <ButtonDefault withImage to={legalSection.appStoreButton}>
              <AppStore />
            </ButtonDefault>
          </FooterButtons>
        </FooterSection>
      </FooterWrapper>
      <RightsReserved>
        <CopyRight className="accent-text-gray">
          ©fena {actualYear}. All rights reserved.
        </CopyRight>
      </RightsReserved>
    </>
  );
};

export default FooterForToolkit;
